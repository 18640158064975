import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  graphql
} from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import NewsRoll from "../components/NewsRoll"

import naze from "../img/sponsor/naze.gif";
import sansin from "../img/sponsor/sansin.gif";
import nss from "../img/sponsor/nss.gif";
import nagaisct from "../img/sponsor/nagaisct.gif";
import ogakon from "../img/sponsor/ogakon.gif";
import kobayashi from "../img/sponsor/kobayashi.gif";
import tawashi from "../img/sponsor/tawashi.gif";
import shichiri from "../img/sponsor/shichiri.gif";
import niigatabondo from "../img/sponsor/niigatabondo.gif";
import obishi from "../img/sponsor/obishi.gif";
import nagaokashinyokinko from "../img/sponsor/nagaokashinyokinko.gif";
import nakatsuyama from "../img/sponsor/nakatsuyama.gif";
import fujimura from "../img/sponsor/fujimura.gif";
import ooi from "../img/sponsor/ooi.gif";
import daiatech from "../img/sponsor/daiatech.gif";
import suzutami from "../img/sponsor/suzutami.gif";
import kurashina from "../img/sponsor/kurashina.gif";
import kimura from "../img/sponsor/kimura.gif";
import toyoyakin from "../img/sponsor/toyoyakin.gif";
import nkc from "../img/sponsor/nkc.gif";
import taiko from "../img/sponsor/taiko.gif";
import fuchimoto from "../img/sponsor/fuchimoto.gif";
import pretech from "../img/sponsor/pretech.gif";
import daishi from "../img/sponsor/daishi.gif";
import yoshii from "../img/sponsor/yoshii.gif";
import asahi from "../img/sponsor/asahi.gif";
import nagaoka from "../img/sponsor/nagaoka.gif";

const sponsor = () => (
  <Layout>
    <section className="section section--gradient">
      <div className="content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <p className="has-text-weight-semibold is-size-4 has-text-centered">
            スポンサー企業様一覧</p>
            <p className="has-text-weight-normal has-text-centered"style={{marginBottom: `80px`}}>
              2019年から2024年の間、ご協賛いただいた企業様の一覧です。</p>
            <div className="content">
              <div className="columns is-multiline">
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={naze} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">NPO法人長岡産業活性化協会NAZE</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={sansin} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社サンシン</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nss} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">エヌ・エス・エス 株式会社</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nagaisct} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社ナガイ</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={ogakon} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">小川コンベヤ株式会社</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={kobayashi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">有限会社小林超硬研磨</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={tawashi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社タワシテック</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={shichiri} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社七里商店</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={niigatabondo} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">新潟ボンド工業株式会社</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={obishi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社大菱計器製作所</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nagaokashinyokinko} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">長岡信用金庫</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nakatsuyama} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社中津山熱処理</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={fujimura} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">藤村クレスト株式会社</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={ooi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社オオイ</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={kurashina} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">有限会社倉品鐵工</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={daiatech} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社ダイアテック</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={suzutami} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社鈴民精密工業所</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={kimura} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社キムラ</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={toyoyakin} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社東洋冶金</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nkc} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社長岡金型</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={taiko} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社大光銀行</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={fuchimoto} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社淵本鋼機</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={pretech} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社プレテック・エヌ</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={daishi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社第四北越銀行</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={yoshii} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">吉井国際特許事務所</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={asahi} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">株式会社アサヒプレシジョン</p>
                  </div>
                </div>
                <div className="is-parent column is-3">
                  <div className="is-featured tile is-child box is-white">
                    <img className="margin-btm-0" src={nagaoka} alt="" width="100%" height="auto" style={{ marginBottom: `20px` }} />
                    <p className="has-text-weight-nornal is-size-6 has-text-centered">長岡市</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default sponsor